import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { sideNavConfigService } from '../sideNavConfigService';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  showNavigationArrows = false;
  showNavigationIndicators = true;
  bgImages = ['./assets/bg1.jpg', './assets/bg2.jpg', './assets/bg3.jpg'];
  showCarousel = false;
  //currentWidth;

  showHome() {
    //console.log("image loaded");
    this.showCarousel = true;
  }

  constructor(
    private config: sideNavConfigService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    //this.currentWidth = event.target.innerWidth;
    if (+window.innerWidth > 813) {
      this.bgImages = ['./assets/bg1.jpg', './assets/bg2.jpg', './assets/bg3.jpg'];
    }
    else {
      this.bgImages = ['./assets/mobile-bg1.jpg', './assets/mobile-bg2.jpg', './assets/mobile-bg3.jpg'];
    }
  }

  ngOnInit() {
    if (+window.innerWidth > 813) {
      this.bgImages = ['./assets/bg1.jpg', './assets/bg2.jpg', './assets/bg3.jpg'];
    }
    else {
      this.bgImages = ['./assets/mobile-bg1.jpg', './assets/mobile-bg2.jpg', './assets/mobile-bg3.jpg'];
    }
    this.config.changeNavStyle({ bgColor: "transparent", textColor: "#FFF", showMenu: false, currentSection: "HOME", showSubSections: false });


  }


}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ProjectsGalleryComponent } from './projects-gallery/projects-gallery.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { AboutmePageComponent } from './aboutme-page/aboutme-page.component';
import { ContactoPageComponent } from './contacto-page/contacto-page.component';


const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot([
    { path: '', component: HomePageComponent, pathMatch: 'full' },
    { path: 'obras', redirectTo: 'obras/todas', pathMatch: 'full'  },
    { path: 'obras/:etapa', component: ProjectsGalleryComponent},
    { path: 'obras/todas', component: ProjectsGalleryComponent},
    { path: 'obras/detalles/:id', component: ProjectDetailsComponent},
    { path: 'sobre-mcf', component: AboutmePageComponent, pathMatch: 'full'},
    { path: 'contacto', component: ContactoPageComponent, pathMatch: 'full'}
  ])],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})

export class sideNavConfigService {

  private configSource = new BehaviorSubject( 
    {
      bgColor: "transparent", 
      textColor: "#FFF",
      showMenu: false,
      currentSection: "HOME",
      showSubSections: false,

    } 
  );

  currentConfig = this.configSource.asObservable();

  constructor() { 
  }

  changeNavStyle(style) {
    this.configSource.next(style)
  }
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-construction-page',
  templateUrl: './construction-page.component.html',
  styleUrls: ['./construction-page.component.scss']
})
export class ConstructionPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { sideNavConfigService } from '../sideNavConfigService';



@Component({
  selector: 'projects-gallery',
  templateUrl: './projects-gallery.component.html',
  styleUrls: ['./projects-gallery.component.scss']
})
export class ProjectsGalleryComponent implements OnInit {

  etapa: string;
  showM: boolean;
  private sub: any;

  obras: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private config: sideNavConfigService
  ) { }


  public getJSON() {
    return this.http.get("assets/obras/obras.json");
  }

  goToDetails() {
    this.config.changeNavStyle({bgColor: "#FFF", textColor:"#000", showMenu: false, currentSection: "OBRAS", showSubSections: false});
    //this.router.navigateByUrl('detalle/', { state: obra });
  }

  ngOnInit() {
    
    if (+window.innerWidth > 813) {this.showM = true;}
    this.config.changeNavStyle({bgColor: "#000", textColor:"#FFF", showMenu: this.showM, currentSection: "OBRAS", showSubSections: true});

    this.getJSON().subscribe(obras => {
      this.obras = obras;
    });
    this.route
      .params.subscribe(params => {
        this.etapa = params["etapa"];
      });
  }
}

import { Component, OnInit, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { GetObrasService } from '../get-obras.service';
import { sideNavConfigService } from '../sideNavConfigService';

@Component({
  selector: 'project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})


export class ProjectDetailsComponent implements OnInit {
  currentIndex = 0;
  @ViewChild('slider', { static: false }) slider: ElementRef<any>;

  public setCurrentIndex(index) {
    this.currentIndex = index;
    console.log(this.currentIndex);
  }

  public scrollRight(): void {

    if (this.currentIndex < (this.imagesArray.length - 1)) {
      let currentPic = this.slider.nativeElement.children[this.currentIndex];
      let cPicwidth = currentPic.scrollWidth;
      this.slider.nativeElement.scrollTo({ left: (this.slider.nativeElement.scrollLeft + cPicwidth), behavior: 'smooth' });
      this.currentIndex++;
      console.log(this.currentIndex)
    }
  }

  public scrollLeft(): void {
    if (this.currentIndex > 0) {
      let currentPic = this.slider.nativeElement.children[this.currentIndex];
      let cPicwidth = currentPic.scrollWidth;
      this.slider.nativeElement.scrollTo({ left: (this.slider.nativeElement.scrollLeft - cPicwidth), behavior: 'smooth' });
      this.currentIndex--;
      console.log(this.currentIndex)

    }

  }

  public imagesArray;

  state$: Observable<object>;
  showNav = true;
  idObra;
  obra;
  obras;
  showM

  //imagesArrayTest = ["fischetti01.jpg","fischetti02.jpg","fischetti03.jpg","fischetti04.jpg","fischetti05.jpg","fischetti06.jpg","fischetti07.jpg","fischetti08.jpg","fischetti09.jpg","fischetti10.jpg","fischetti11.jpg"];

  constructor(
    private http: HttpClient,
    public route: ActivatedRoute,
    private obrasService: GetObrasService,
    private config: sideNavConfigService,
  ) {

  }

  private getObras() {
    this.obrasService.getObras()
      .subscribe(obras => {
        this.obras = obras;
        this.obra = obras.filter((obra) => obra.id == this.idObra)[0];
      });
  }

  public getJSON(path): any {
    return this.http.get('dirfiles.php?path=' + path);
  }
  ngOnChanges() {
    this.route.params.subscribe(params => {
      this.idObra = params["id"];
      //this.obras = this.getData();
    });
  };

  ngOnInit() {
    /*this.imagesArrayTest = this.imagesArrayTest.map(image => {
      return "./assets/obras/01/" + image;
    })*/

    if (+window.innerWidth > 813) { this.showM = true; }
    this.config.changeNavStyle({ bgColor: "#FFF", textColor: "#000", showMenu: this.showM, currentSection: "DETALLES", showSubSections: false });

    this.route.params.subscribe(params => {
      this.idObra = params["id"];
      //this.obras = this.getData();
      console.log(this.idObra);
      this.http.get('/assets/obras/obras.json').toPromise()
        .then((data: any) => {
          this.obras = data;
          this.obra = data.filter((obra: any) => obra.id == this.idObra)[0];
          console.log(this.obra)
            ; return this.obra;
        })
        .then(async (obra) => {
          console.log(obra.path)
          this.http.get('dirfiles.php?path=' + obra.path).subscribe((fileList) => {
            console.log(fileList)
            this.imagesArray = fileList;
            let index = this.imagesArray.indexOf('cover.jpg');
            if (index > -1) {
              this.imagesArray.splice(index, 1);
            }
            this.imagesArray = this.imagesArray.map(image => {
              return this.obra.path + image;
            });;
          })
        }).catch((e) => console.error(e))
    });
  }
}
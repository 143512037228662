import { Component, OnInit } from '@angular/core';
import { sideNavConfigService } from '../sideNavConfigService';

@Component({
  selector: 'app-aboutme-page',
  templateUrl: './aboutme-page.component.html',
  styleUrls: ['./aboutme-page.component.scss']
})
export class AboutmePageComponent implements OnInit {
  showM
  constructor(
    private config: sideNavConfigService,
  ) { }

  ngOnInit() {
    if (+window.innerWidth > 813) { this.showM = true; }
    this.config.changeNavStyle({ bgColor: "#000", textColor: "#FFF", showMenu: this.showM, currentSection: "SOBRE MCF", showSubSections: false });

  }

}

import { Injectable,  } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import obras from '../assets/obras/obras.json';


@Injectable({
  providedIn: 'root'
})
export class GetObrasService {

  getObras(): Observable<any>{
    return this.httpClient.get('/assets/obras/obras.json');
    //let obras = await this.httpClient.get('/assets/obras/obras.json')
    //return of(obras);
  }
  constructor(private httpClient: HttpClient) {
  }
}
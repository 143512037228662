import { Component, HostListener, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { sideNavConfigService } from '../sideNavConfigService';


@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  innerWidth:any;



  constructor(
    private config: sideNavConfigService
  ) { }

  section: string = 'HOME';
  showHideObras: boolean = false;
  showHideMenu: boolean = false;
  currentPath;


  textColor: string = "#FFF"
  bgColor: string = "#000";

  showMenu() {
    this.showHideMenu = !this.showHideMenu;
  }

  showObras() {
    this.showHideObras = !this.showHideObras;
  }

  hideMenuMobile () {
    if (+window.innerWidth > 813) {
      return;
    }
    else {
      this.showHideMenu = !this.showHideMenu;
    }
  }

  ngOnInit() {

    this.config.currentConfig.subscribe(config => {
      this.textColor = config.textColor;
      this.bgColor = config.bgColor;
      this.showHideMenu = config.showMenu;
      this.showHideObras = config.showSubSections;
      this.section = config.currentSection;
    });
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule, NgbCarousel} from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { LogoComponent } from './logo/logo.component';
import { AppComponent } from './app.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { ProjectsGalleryComponent } from './projects-gallery/projects-gallery.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { MyFilterPipe } from './pipe-filter-etapa';
import { NgImageSliderModule } from 'ng-image-slider';
import { SliderModule } from 'angular-image-slider';
import { sideNavConfigService} from './sideNavConfigService';
import { ConstructionPageComponent } from './construction-page/construction-page.component';
import { ContactoPageComponent } from './contacto-page/contacto-page.component';
import { AboutmePageComponent } from './aboutme-page/aboutme-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogoHorizontalComponent } from './logo-horizontal/logo-horizontal.component';
import { ImageInViewDirective } from './image-in-view.directive';

@NgModule({
  declarations: [
    LogoComponent,
    LogoHorizontalComponent,
    AppComponent,
    SideNavComponent,
    ProjectsGalleryComponent,
    HomePageComponent,
    ProjectDetailsComponent,
    MyFilterPipe,
    ConstructionPageComponent,
    ContactoPageComponent,
    AboutmePageComponent,
    ImageInViewDirective,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    NgImageSliderModule,
    SliderModule,
    ReactiveFormsModule,
  ],

  providers: [
    sideNavConfigService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

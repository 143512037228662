import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { sideNavConfigService } from '../sideNavConfigService';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


@Component({
  selector: 'app-contacto-page',
  templateUrl: './contacto-page.component.html',
  styleUrls: ['./contacto-page.component.scss']
})
export class ContactoPageComponent implements OnInit {
  showM: boolean = false;
  contactForm;
  formError;
  formResponse;



  constructor(
    private http: HttpClient,
    private config: sideNavConfigService,
    private formBuilder: FormBuilder
  ) {
    this.contactForm = this.formBuilder.group({
      "name": '',
      "email": '',
      "message": ''
    });
  }

  onSubmit(contactData:any) {
    
    this.formResponse = "";

    this.http.post('mail.php', contactData, { responseType: 'text'}).subscribe(
      (res) => { 
        let response = JSON.parse(res);
        this.formResponse = response.message.toString();
        this.contactForm.reset()},
      (err) => { this.formResponse = err.message });
  }

  ngOnInit() {
    if (+window.innerWidth > 813) { this.showM = true; }
    this.config.changeNavStyle({ bgColor: "#000", textColor: "#FFF", showMenu: this.showM, currentSection: "CONTACTO", showSubSections: false });

  }

}

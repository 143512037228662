import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'etapaIs',
    pure: false
})
export class MyFilterPipe implements PipeTransform {
    transform(items: any[], filter: {"etapa":string}): any {
        if (!items || !filter || filter.etapa === 'todas') {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter(item => item.etapa.indexOf(filter.etapa) !== -1);
    }
}
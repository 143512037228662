import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { sideNavConfigService } from './sideNavConfigService'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})


export class AppComponent {

  //sub: Object;
  //filter: String;

  bgLoaded = false;

  title = 'MCF-ARQ';
  //opacityFade = 1;

  //currentSection: string = 'HOME';

  showHome() {
    this.bgLoaded = true;
  }

  /*
  onSectionClick(item: string) {
    this.currentSection = item;
    switch (this.currentSection) {
      case "HOME":
        this.currentClass = "homePage"
        //this.textColor = "#FFF";
        //this.bgColor = "transparent";
        break;
      default:
        this.currentClass = "noBackground"
        //this.textColor = "#FFF";
        //this.bgColor = "#000";
        break;
    }
  }*/

  constructor(private config: sideNavConfigService) {}

  ngOnInit() {
    this.config.changeNavStyle({bgColor: "transparent", textColor:"#FFF", showMenu: false, currentSection: "HOME", showSubSections: false});
  }
}
